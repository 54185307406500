<template>
  <div class="home">
    <section class="home--section-left">
      <a :href="bannerUrl" target="_blank" class="home__link">
        <v-img
          :src="require(`@/assets/images/banner-${currentLanguage}.png`)"
          position="top center"
          max-width="100%"
          height="100vh"
        >
        </v-img>
      </a>
    </section>
    <section class="home--section-right pa-4 pa-sm-6 pa-lg-8">
      <div class="home__container">
        <header class="home__container__header">
          <div class="home__container__header__left">
            <a href="https://www.precisionski.fr/" target="_blank">
              <img :src="require('@/assets/images/logo-precision.svg')" alt="Précision Ski" />
            </a>
          </div>
        </header>
        <div class="home__container__main">
          <div class="home__container__main__intro text-center mt-10">
            {{ $t('home.intro') }}
          </div>
          <h1 class="home__container__main__title text-center mt-4">{{ $t('home.title') }}</h1>
          <div class="home__container__main__separator">
            <span class="home__container__main__separator__label px-4">{{ $t('home.separator') }}</span>
          </div>
          <div class="home__container__main__caption text-center" v-html="$t('home.caption')"></div>
          <div class="home__container__main__code mt-8">
            <div class="home__container__main__code__title">
              {{ $t('home.code.title') }}
            </div>
            <v-otp-input
              class="home__container__main__code__input"
              length="7"
              :value="code"
              @input="onCodeInput"
              @change="onCodeChange"
            ></v-otp-input>
            <div class="home__container__main__code__error red--text">
              <div v-if="error">{{ $t('home.code.error') }}</div>
            </div>
          </div>
          <v-btn
            depressed
            color="secondary"
            x-large
            height="54"
            :href="itemByLocale.href"
            class="home__container__main__link text-h6 mt-8"
            :disabled="disabled"
          >
            {{ $t('home.cta') }}
          </v-btn>
        </div>
        <div class="home__container__language mt-6">
          <div class="home__container__language__label mb-2">{{ $t('home.language.label') }}</div>

          <v-select
            class="home__container__language__label__selector"
            :items="items"
            v-model="currentLanguage"
            outlined
            hide-details
            solo
            @change="onLocaleChange"
          >
            <template v-slot:selection="{ item }">
              <span class="d-flex justify-center text-uppercase" style="width: 100%">
                {{ item.text }}
              </span>
            </template>
          </v-select>
        </div>
        <div class="text-center mt-6">
          <div class="home__container__main__condition" v-html="$t('home.firstCondition')"></div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { codes } from '@/config/codes.config'

export default {
  name: 'Home',
  data: vm => ({
    currentLanguage: vm.$i18n.locale,
    code: '',
    disabled: true,
    error: false,
  }),
  metaInfo: vm => ({
    title: vm.$t('meta.title'),
    meta: [{ name: 'description', content: vm.$t('meta.description') }],
  }),
  computed: {
    itemByLocale() {
      return this.items.find(item => item.value === this.currentLanguage)
    },
    bannerUrl() {
      const item = this.itemByLocale
      return item.website
    },
    items() {
      return [
        {
          text: 'Français',
          value: 'fr',
          href: `https://avis-precision.gtr.cx/?g=${this.code}FR`,
          website: 'https://www.precisionski.fr/',
        },
        {
          text: 'English',
          value: 'en',
          href: `https://reviews-precision.gtr.cx/?g=${this.code}EN`,
          website: 'https://www.precisionski.fr/',
        },
      ]
    },
  },
  methods: {
    onLocaleChange(locale) {
      this.$i18n.locale = locale
    },
    onCodeInput(code) {
      this.code = code.toUpperCase()
      this.disabled = true
      this.error = false
    },
    onCodeChange() {
      if (codes.includes(this.code)) {
        this.disabled = false
        this.error = false
      } else {
        this.disabled = true
        this.error = true
      }
    },
  },
}
</script>

<style lang="scss">
.home {
  display: flex;
  min-height: 100%;

  &--section-left {
    display: none;
    position: fixed;

    @media (min-width: map-get($grid-breakpoints, 'md')) {
      display: flex;
      width: calc(100% - 600px);
    }

    @media (min-width: map-get($grid-breakpoints, 'lg')) {
      width: calc(100% - 900px);
    }

    @media (min-width: map-get($grid-breakpoints, 'xl')) {
      width: calc(100% - 1100px);
    }

    @media (min-width: map-get($grid-breakpoints, 'xxl')) {
      width: calc(100% - 1300px);
    }
  }

  &__link {
    max-width: 100%;
  }

  &--section-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $color-bg;
    width: 100%;

    @media (min-width: map-get($grid-breakpoints, 'md')) {
      margin-left: calc(100% - 600px);
      width: 600px;
    }

    @media (min-width: map-get($grid-breakpoints, 'lg')) {
      margin-left: calc(100% - 900px);
      width: 900px;
    }

    @media (min-width: map-get($grid-breakpoints, 'xl')) {
      margin-left: calc(100% - 1100px);
      width: 1100px;
    }

    @media (min-width: map-get($grid-breakpoints, 'xxl')) {
      margin-left: calc(100% - 1300px);
      width: 1300px;
    }
  }

  &__logo {
    position: absolute;
    bottom: $gutter-tablet;
    left: $gutter-tablet;

    @media (min-width: map-get($grid-breakpoints, 'lg')) {
      bottom: $gutter-desktop;
      left: $gutter-desktop;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;

    @media (min-width: map-get($grid-breakpoints, 'sm')) {
      justify-content: space-between;
      max-width: 550px;
    }

    @media (min-width: map-get($grid-breakpoints, 'md')) {
      justify-content: space-between;
      max-width: 700px;
    }

    &__header {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 32px;

      &__left,
      &__center,
      &__right {
        display: flex;
        position: relative;
        justify-content: center;

        a {
          display: inline-flex;

          img {
            max-width: 100%;
            height: 60px;
          }
        }
      }

      &__center {
        &:before {
          position: absolute;
          top: 50%;
          left: -16px;
          transform: translateY(-50%);
          background-color: rgba($color-text-main, 0.8);
          width: 2px;
          height: 70%;
          content: '';
        }
      }
    }

    &__main {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-transform: uppercase;

      &__intro {
        color: $color-text-main;
        text-transform: initial;
        font-size: 1.25rem;
      }

      &__title {
        color: $color-text-accent;
        font-size: 1.875rem;
        font-weight: bold;

        @media (min-width: map-get($grid-breakpoints, 'sm')) {
          font-size: 2.5rem;
        }
      }

      &__separator {
        position: relative;
        width: 100%;
        text-align: center;
        color: $color-text-main;
        font-size: 1.5rem;
        font-weight: bold;

        @media (min-width: map-get($grid-breakpoints, 'sm')) {
          font-size: 1.75rem;
        }

        &:before {
          position: absolute;
          top: 50%;
          right: 0;
          left: 0;
          transform: translateY(-50%);
          background-color: rgba($color-text-main, 0.7);
          height: 2px;
          content: '';
        }

        &__label {
          position: relative;
          background-color: $color-bg;
        }
      }

      &__subtitle {
        text-transform: uppercase;
        color: $color-text-accent;
        font-size: 1.7rem;
        font-weight: bold;

        @media (min-width: map-get($grid-breakpoints, 'sm')) {
          font-size: 2.25rem;
        }
      }

      &__caption {
        color: $color-text-main;
        font-size: 1.25rem;

        @media (min-width: map-get($grid-breakpoints, 'sm')) {
          font-size: 1.75rem;
        }
      }

      &__condition {
        text-transform: initial;
        color: $color-text-alt;
        font-size: 0.875rem;

        a {
          color: $color-text-alt !important;
        }
      }

      &__code {
        width: 100%;
        max-width: 450px;
        text-transform: none;
        color: $color-text-main;
        text-align: center;

        &__error {
          margin-top: 4px;
          font-size: 0.75rem;
          min-height: 18px;
        }

        &__input {
          input {
            text-transform: uppercase;
          }
        }
      }

      &__link {
        font-weight: bold;
      }
    }

    &__language {
      display: flex;
      flex-direction: column;
      align-items: center;

      &__label {
        text-transform: uppercase;
        color: $color-text-main;
        font-size: 1.25rem;

        @media (min-width: map-get($grid-breakpoints, 'sm')) {
          font-size: 1.5rem;
        }
      }
    }
  }
}
</style>
